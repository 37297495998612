
import cmsBlock from '~/mixins/cmsBlock'
import GTM from '~/helpers/gtm'

export default {
  name: 'CmsBanner',
  mixins: [cmsBlock],
  computed: {
    carousels () {
      return [
        {
          css: this.block?.isJson ? 'cms-banner-mobile' : '',
          items: this.mobileBlockContent.items,
          isJson: this.mobileBlock?.isJson,
          size: {
            width: 400,
            height: 225,
          },
        },
        {
          css: this.mobileBlock?.isJson ? 'cms-banner-desktop' : '',
          items: this.blockContent.items,
          isJson: this.block?.isJson,
          size: {
            width: 912,
            height: 342,
          },
        },
      ]
    },
  },

  methods: {
    onBannerClick (item) {
      const { salesManagoId = '' } = this.$store.state?.user?.profile

      this.$gtm.push({ ecommerce: null })
      this.$gtm.push(GTM.trackProductSelectFromList('select_item', item, 0, salesManagoId))
    },
  },
}
