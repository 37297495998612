export default {
  props: {
    product: {
      type: Object,
      required: true,
    },

    productType: {
      type: String,
      default: '',
    },
  },

  computed: {
    minRegularPrice () {
      return this.product?.priceRange?.minimumPrice
    },

    minFinalPrice () {
      return this.product?.priceRange?.minimumFinalPrice
    },

    minMediclubPrice () {
      return this.product?.priceRange?.minimumMediclubPrice
    },
  },
}
