
import cmsBlock from '~/mixins/cmsBlock'

export default {
  name: 'CmsService',
  mixins: [cmsBlock],

  data: () => ({
    hide: false,
  }),
}
