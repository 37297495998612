
import { removeDiacritics } from '~/utils/Utils'
import cmsBlock from '~/mixins/cmsBlock'
import { telemedicineValue, inTheOfficeValue, officeOptionLabel, telemedicineOptionLabel } from '~/constants/slotsVisitType'

export default {
  name: 'CmsVisits',
  mixins: [cmsBlock],

  data () {
    return {
      telemedicineLabel: {
        first: { text: 'Telemedicine', value: telemedicineValue },
        second: { text: 'In the office', value: inTheOfficeValue },
      },

      filter: {
        telemedicine: inTheOfficeValue,
      },

      selectedOption: officeOptionLabel,
      speciality: null,
      city: null,
    }
  },

  computed: {
    blockData () {
      const blockData = JSON.parse(JSON.stringify(this.blockContent))

      const { office, telemedicine } = blockData.tabs[0];

      [office, telemedicine].forEach(type => type.items.forEach((item) => {
        item.value = item.text.replaceAll(' ', '-')
        item.cities.forEach((city) => {
          city.value = city.text.replaceAll(' ', '-')
        })
      }))

      return blockData
    },

    specialityList () {
      return this.blockData.tabs[0][`${this.selectedOption}`].items
    },

    cityList () {
      const city = this.blockData.tabs[0][`${this.selectedOption}`].items.find(item => item.value === this.speciality)

      return city?.cities || []
    },
  },

  watch: {
    city () {
      const query = this.filter.telemedicine === telemedicineValue ? { query: { telemedicine: telemedicineValue } } : {}

      this.$router.push({
        path: this.localePath(removeDiacritics(`/p/${this.speciality}/${this.city}`)),
        ...query,
      })
    },

    filter: {
      deep: true,
      handler () {
        this.selectedOption = +this.filter.telemedicine === inTheOfficeValue ? officeOptionLabel : telemedicineOptionLabel
      },
    },
  },
}
