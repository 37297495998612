import { render, staticRenderFns } from "./CmsVisits.vue?vue&type=template&id=14230a9a&scoped=true&"
import script from "./CmsVisits.vue?vue&type=script&lang=js&"
export * from "./CmsVisits.vue?vue&type=script&lang=js&"
import style0 from "./CmsVisits.vue?vue&type=style&index=0&id=14230a9a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14230a9a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseFormRadioSwitcher: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/form/BaseFormRadioSwitcher.vue').default,BaseFormSelect: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/form/BaseFormSelect.vue').default,BaseTabsItem: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/tabs/BaseTabsItem.vue').default,HeaderSearch: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/Header/Search/HeaderSearch.vue').default,BaseTabs: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/tabs/BaseTabs.vue').default})
