import { render, staticRenderFns } from "./CmsBanner.vue?vue&type=template&id=3048584a&scoped=true&"
import script from "./CmsBanner.vue?vue&type=script&lang=js&"
export * from "./CmsBanner.vue?vue&type=script&lang=js&"
import style0 from "./CmsBanner.vue?vue&type=style&index=0&id=3048584a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3048584a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImage: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseImage.vue').default,BaseCarousel: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/carousel/BaseCarousel.vue').default})
