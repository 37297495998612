
export default {
  name: 'PriceLowest',

  props: {
    product: {
      type: Object,
      default: null,
    },

    price: {
      type: Object,
      default: null,
    },

    fieldBemModifiers: {
      type: Array,
      default: () => [],
    },

    isMedi: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    lowestPrice () {
      if (this.price) {
        return this.price
      }

      return this.product?.priceRange?.lowestPriceHistory
    },
  },
}
