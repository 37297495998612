export const getCanonicalUrl = (route, appUrl = '') => {
  const allowedQueryParams = ['page']

  const host = `${appUrl}${route.path}`
  const query = Object.entries(route.query)
    .filter(([qKey]) => allowedQueryParams.includes(qKey))
    .map(([qKey, qVal]) => `${qKey}=${qVal}`).join('&')

  return `${host}${query ? `?${query}` : ''}`
}
