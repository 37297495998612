export default {
  methods: {
    isPriceRange (product) {
      const { minimumPrice, maximumPrice } = product?.priceRange || {}

      switch (product._typename) {
        case 'MedicalCheckProduct':
        case 'OneTimeServiceProduct':
        case 'PrepaidCardProduct':
        case 'PhysicalProduct':
          return minimumPrice?.value !== maximumPrice?.value

        case 'LensesProduct':
          return false
      }

      return true
    },
  },
}
