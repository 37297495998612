
export default {
  name: 'BaseFormRadioSwitcher',
  props: {
    labelFirstPlace: {
      type: Object,
      required: true,
    },

    labelSecondPlace: {
      type: Object,
      required: true,
    },

    value: {
      type: [String, Boolean, Number],
      default: '',
    },
  },

  methods: {
    onToggle () {
      this.$emit('input',
        this.value === this.labelFirstPlace.value
          ? this.labelSecondPlace.value
          : this.labelFirstPlace.value,
      )
    },
  },
}
