
import { createNamespacedHelpers } from 'vuex'
import userTypes from '~/store/user/types'

const {
  mapGetters: mapGettersUser,
} = createNamespacedHelpers('user')

export default {
  name: 'ProductPrice',

  props: {
    priceBemModifiers: {
      type: Array,
      default: () => ['align-right'],
    },

    fieldBemModifiers: {
      type: Array,
      default: () => [],
    },

    from: {
      type: Boolean,
      default: true,
    },

    regular: {
      type: Object,
      default: null,
    },

    final: {
      type: Object,
      default: null,
    },

    mediclub: {
      type: Object,
      default: null,
    },

    isMedi: {
      type: Boolean,
      default: false,
    },

    sponsored: {
      type: Boolean,
      default: false,
    },

    isLogoPin: {
      type: Boolean,
      default: false,
    },

    isBigSquareLogo: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGettersUser({
      isMediclubMember: userTypes.GET_IS_MEDICLUB_MEMBER,
    }),

    hasFinalPrice () {
      return !!this.final
    },

    hasMediclubPrice () {
      return !!this.mediclub
    },
  },
}
