
import { createNamespacedHelpers } from 'vuex'
import { getCanonicalUrl } from '~/services/canonicalUrl/canonicalUrl'
import title from '~/config/title'

const {
  mapState: mapStateConfig,
} = createNamespacedHelpers('storeConfig')

export default {
  // eslint-disable-next-line vue/match-component-file-name
  name: 'PageIndex',
  layout: 'default',

  async asyncData ({ app, store }) {
    await store.dispatch('staticBlock/getByIdentifiers', [
      'visits-block',
      'service-block',
      'banner-block',
      'banner-block-mobile',
      'product-block',
      'tail-block',
      'icon-block',
      'category-block',
      'brand-block',
    ])

    store.dispatch('breadcrumbs/setBreadcrumbs', {
      breadcrumbs: [],
      name: app.i18n.t('Homepage'),
    })
  },

  head () {
    return {
      title: title[this.$config.storeCode]?.[this.$i18n.locale] ?? this.$config.storeName,

      meta: [
        {
          hid: 'robots',
          name: 'robots',
          content: this.$i18n.locale === 'pl' ? 'all' : 'none',
        },
      ],

      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: getCanonicalUrl(this.$route, this.$config.appUrl),
        },
      ],
    }
  },

  computed: {
    ...mapStateConfig(['storeConfig']),
  },

  mounted () {
    this.$gtm.push({ event: 'page_view' })
  },

  jsonld () {
    const { storeName, appUrl, availableLocales, storeCode } = this.$config
    const {
      storeInformationName,
      storeInformationPhone,
      storeInformationEmail,
      storeInformationCity,
      storeInformationPostcode,
      storeInformationAddress,
    } = this.storeConfig

    // @todo do przebudowy jak się nam zmieni struktura grafik
    const logo = storeCode === 'damian'
      ? `${appUrl}/_nuxt/assets/sprite/damian-logo.svg`
      : `${appUrl}/_nuxt/assets/sprite/medistore-logo.svg`

    return {
      '@context': 'https://schema.org',
      '@graph': [
        {
          '@type': 'WebSite',
          author: storeInformationName,
          copyrightHolder: storeInformationName,
          url: appUrl,
          potentialAction: {
            '@type': 'SearchAction',
            target: `${appUrl}?q={search_term_string}`,
            'query-input': 'required name=search_term_string',
          },
        },
        {
          '@type': 'Organization',
          brand: storeName,
          url: appUrl,
          email: storeInformationEmail,
          copyrightHolder: storeName,
          logo,
          telephone: storeInformationPhone,

          address: {
            '@type': 'PostalAddress',
            addressLocality: storeInformationCity,
            postalCode: storeInformationPostcode,
            streetAddress: storeInformationAddress,
          },

          contactPoint: [{
            '@type': 'ContactPoint',
            telephone: storeInformationPhone,
            contactType: 'customer service',
            availableLanguage: availableLocales,
          }],
        },
        {
          '@type': 'Physician',
          paymentAccepted: 'Credit Card, BLIK, Przelewy24, PayPo',
          logo,
          isAcceptingNewPatients: true,
          medicalSpecialty: ['MedicalOrganization', 'Physician'],
          availableService: 'MedicalTherapy',
        },
      ],
    }
  },

}
