
import expiringStorage from '@/utils/expiringStorage'

export default {
  name: 'BaseTabs',

  props: {
    storageKey: {
      type: String,
      default: null,
    },

    tabsOnMobile: {
      type: Boolean,
      default: false,
    },

    tabsListEl: {
      type: String,
      default: 'ul',
    },

    tabsItemEl: {
      type: String,
      default: 'li',
    },

    tabsAccordion: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    let tabs, active

    try {
      const items = this.$slots.default.filter(child => child.componentOptions)

      tabs = items.map(item => item.componentOptions.propsData.name)
      const defaultTab = items.find(item => 'isDefaultTab' in item.componentOptions.propsData)

      active = defaultTab
        ? defaultTab.componentOptions.propsData.name
        : expiringStorage.get(this.storageKey, tabs[0])

      const itemActive = items.find(item => item.componentOptions.propsData.name === active) ?? items[0]

      active = itemActive.componentOptions.propsData.name

      itemActive.componentOptions.propsData.isInitialActive = true
    } catch {
      tabs = []
    }

    return {
      active,
      tabs,
      observer: null,
    }
  },

  watch: {
    active (newTabName, oldTabName) {
      const oldTabActive = this.$children.find(item => item.name === oldTabName)
      const newTabActive = this.$children.find(item => item.name === newTabName)

      oldTabActive?.setActive(false)
      newTabActive?.setActive(true)
    },
  },

  mounted () {
    this.observer = new MutationObserver(this.validate)

    this.observer.observe(this.$el, {
      childList: true,
      subtree: true,
    })
  },

  beforeDestroy () {
    this.observer.disconnect()
  },

  methods: {
    setActive (tabName) {
      this.active = tabName

      expiringStorage.set(this.storageKey, tabName)
    },

    validate () {
      this.tabs = this.$children.map(c => c.$props.name)

      const newActiveTab = this.tabs.includes(this.active) ? this.active : this.tabs.slice(-1)[0]

      this.$nextTick(() => {
        this.setActive(newActiveTab)
        this.$children.find(item => item.name === newActiveTab)?.setActive(true)
      })
    },
  },
}
