
import { productsForDatalayer } from '~/utils/productsForDatalayer'

export default {
  name: 'BaseCarousel',
  props: {
    items: {
      type: Array,
      default: () => [],
    },

    showArrows: {
      type: Boolean,
      default: false,
    },

    arrowSize: {
      type: String,
      default: 'md',
    },

    arrowActiveColor: {
      type: String,
      default: 'color-primary-4',
    },

    showDots: {
      type: Boolean,
      default: false,
    },

    isTitleHidden: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: '',
    },

    gtmLabel: {
      type: String,
      default: '',
    },

    autoplay: {
      type: Boolean,
      default: false,
    },

    autoplayInterval: {
      type: Number,
      default: 5000,
    },

    onInViewportEvent: {
      type: String,
      default: 'view_item_list',
    },

    slidesPerView: {
      type: Object,
      default: () => ({}),
      validator: (item) => {
        const config = Object.keys(item)

        return ['xxs', 'xs', 'sm', 'md', 'lg', 'xl'].every((v) => {
          return config.includes(v)
        })
      },
    },
  },

  data () {
    return {
      padding: 16,
      currentPosition: 0,
      autoplayTimeout: null,
      slideEndTimeout: null,
    }
  },

  computed: {
    isStartPoint () {
      return this.currentPosition === 0
    },

    isEndPoint () {
      return this.currentPosition + this.itemsInSight >= this.items.length
    },

    itemWidth () {
      return ((this.$refs.slider.clientWidth / this.itemsInSight) - this.padding + (this.padding / this.itemsInSight)) + this.padding
    },

    itemsInSight () {
      return this.slidesPerView[this.$css.breakpoints.name]
    },

    offset () {
      return this.itemWidth * this.itemsInSight
    },

    isArrowVisible () {
      return this.showArrows && this.items.length > this.itemsInSight
    },

    isDotVisible () {
      return this.showDots && this.items.length > this.itemsInSight
    },
  },

  watch: {
    $css: {
      deep: true,
      handler () {
        this.$refs.sliderContainer.scrollTo(0, 0)
        this.currentPosition = 0
        this.onAutoplay()
      },
    },
  },

  mounted () {
    this.onAutoplay()
  },

  beforeDestroy () {
    clearTimeout(this.autoplayTimeout)
    clearTimeout(this.slideEndTimeout)
  },

  methods: {
    onInViewport () {
      const { salesManagoId = '' } = this.$store.state?.user?.profile

      let eventData = {}

      switch (this.onInViewportEvent) {
        case 'view_promotion':
          eventData = {
            creative_name: 'Summer Banner',
            creative_slot: 'featured_app_1',
            promotion_id: 'P_12345',
            promotion_name: 'Summer Sale',
          }
          break
        default:
          eventData = {
            item_list_id: this.gtmLabel,
            item_list_name: this.gtmLabel,
          }
          break
      }

      this.$gtm.push({
        event: this.onInViewportEvent,
        ecommerce: {
          ...eventData,
          items: productsForDatalayer(this.items),
          contactId: salesManagoId,
        },
      })
    },

    onAutoplay () {
      clearTimeout(this.autoplayTimeout)

      if (this.autoplay) {
        this.autoplayTimeout = setTimeout(this.onAutoplayNext, this.autoplayInterval)
      }
    },

    onAutoplayNext () {
      if (this.currentPosition < this.items.length - parseInt(this.itemsInSight)) {
        this.onNext()
      } else {
        this.onExactPoint(0)
      }
    },

    onSwipeHandler () {
      clearTimeout(this.autoplayTimeout)

      this.slideEndTimeout = setTimeout(() => {
        this.currentPosition = parseInt(this.$refs.sliderContainer.scrollLeft / this.offset) * this.itemsInSight
        this.onAutoplay()
      }, 1000)
    },

    onNext () {
      this.$refs.sliderContainer.scrollLeft += this.offset
      this.currentPosition += this.itemsInSight

      this.onAutoplay()
    },

    onPrev () {
      this.$refs.sliderContainer.scrollLeft -= this.offset
      this.currentPosition -= this.itemsInSight

      this.onAutoplay()
    },

    onExactPoint (index) {
      const prevPosition = this.currentPosition - index
      const nextPosition = index - this.currentPosition

      if (index > this.currentPosition) {
        this.$refs.sliderContainer.scrollLeft += (this.offset * nextPosition)
      } else {
        this.$refs.sliderContainer.scrollLeft -= (this.offset * prevPosition)
      }

      this.currentPosition = index
      this.onAutoplay()
    },

    isActive (element) {
      return element === this.currentPosition
    },
  },
}
